import React, { Component } from "react";
import Footer from "./Footer";
import {
    PnmacAppBar,
    PnmacMediaQuery
} from 'pnmac-universal-components'



class GenericLayout extends Component {

    constructor(props) {
        super(props)
        this.state = {
          
        }
    }

    render() {
        let { showHeader} = this.props
        return (
            <div>
              
                <PnmacMediaQuery queries={{
                    xSmall: "(max-width: 281px)",
                    small: "(max-width: 599px)",
                    medium: "(min-width: 600px) and (max-width: 1199px)",
                    large: "(min-width: 1200px)"
                }}>
                    {matches => (
                        <div>
                            {showHeader? <PnmacAppBar
                                style={{
                                    height: 75,
                                    justifyContent: "flex-end"
                                }}
                                
                            >
                            </PnmacAppBar>: null}
                            <main>
                      
                                {this.props.children}
                            </main>

                            <Footer {...this.props} matches={matches} />
                        </div>
                    )}
                </PnmacMediaQuery>
            </div >
        );
    }
}




export default GenericLayout;
