
import React, { Component, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import PrivateRoute from './Authentication/PrivateRoute.js';
import GenericLayout from './components/GenericLayout.js';
import { bindActionCreators } from "redux";
import { LocalizationProvider } from "@material-ui/pickers";
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { getActiveFeatures, getCookie, setCookie } from './utils.js';
import './PayoffTileStyle.css'
import Default from './components/Default.js'
import Layout from './components/Layout';
import * as Sentry from "@sentry/react";
import { MarketingScripts } from './components/MarketingScripts.js'
import TagManager from 'react-gtm-module';
// import FadeLoader from 'pnmac-universal-components/src/Spinners/FadeSpinner/FadeSpinner.js';
import Registration from './components/Registration'
import MovrRed from './components/MovrRedirect.js';
import { connect } from 'react-redux'
import {
    AppActions,
    Interstitial,
    PnmacSessionTimer,
    ForceUpdate,
    BillMatrixHolder,
    MakeADraw,
    Offers,
    AchSuccess,
    AppEvents,
    PnmacContext,
    PendingPayments,
    Payment,
    SuccessfulLogout,
    MortgageInsurance,
    WisconsinTaxOptions,
    EmailVerification,
    Landing,
    Mod,
    ModForms,
    PayoffDemand,
    LoanActivity,
    LoanEscrow,
    MessageCenter,
    StatementsAndDocuments,
    AmortizationCalculator,
    FAQ,
    ContactUs,
    LoanSummary,
    AccountSettingsPage,
    EscrowAnalysisPaymentChange,
    TaxOptions
} from 'pnmac-universal-components';
import MovrRedirect from './pages/MovrRedirect/index.jsx';




const { PENNYWISE, CHANGE_LANGUAGE, SET_TOKEN, LOG_IN, LOG_OUT, OFFLINE_MODE, USER_IDLE_TIMEOUT } = AppActions
const { TrackerHitTypeLID_ENUM, TrackerActionLID_ENUM, TrackerCategoryLID_ENUM, UserLanguage_ENUM } = AppEvents

let env = window.location.hostname ?
    window.location.hostname.indexOf("-dev") > -1 ? "development" :
        window.location.hostname.indexOf("-qa") > -1 ? "qa" :
            window.location.hostname.indexOf("-stg") > -1 ? "staging" :
                window.location.hostname.indexOf("localhost") == -1 ? "production" : "debug" : "debug"

TagManager.initialize({
    gtmId: 'GTM-T8X4NKQ'
});
function checkConfig(urlParams, props) {
    let tok = urlParams.get('token');
    fetch(`/api/main/get_app_info?appId=23`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${tok}`,
            'Content-Type': 'application/json',
            'pnmac_user': 'True'
        }
    }).then(s => {
        if (s.status >= 200 && s.status <= 299) {
            return s.json()
        } else {
            window.location.href = '/oauth/index'
            return false
        }
    }).then(ss => {
        if (ss) {
            let url = ss;
            if (url) {
                props.dispatch({
                    type: PENNYWISE,
                    is: true,
                    url: '/',
                    token: tok,
                    loanId: urlParams.get('loanId')
                })
                props.pennyWiseAuth(tok, "/")
                props.history.push("/")
            }
        }
    }).catch(err => {
        window.location.href = '/oauth/log_out'
        console.log("Err", err)
    })
}

//if (window?.performance?.navigation?.type === 2 && window?.location?.pathname === "/") {
//    window.location.reload();
//}

const LogIn = () => {
    return window.location.href = `/oauth/index`
}
class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            routeFound: true,
            register: null,
            languageSet: false,
            isMounted: false,
            urlParams: new URLSearchParams(window.location.search),
            directed: false,
            isProductAndFeaturesEnabled: false,

        }
        let that = this
        window.windowNav = (nav) => {

                props.history.push(`/${nav.route}`);
           
        }

        this.channel = new BroadcastChannel('app-navigate')

        if (this.state.urlParams.has('dlink')) {
            const routerName = this.state.urlParams.get('dlink');
            props.trackerDispatcher(UserLanguage_ENUM.English,
                TrackerCategoryLID_ENUM.Button,
                TrackerHitTypeLID_ENUM.Event,
                TrackerActionLID_ENUM.Click,
                `Web clicked dlink ${routerName}`, 0)
        }

        if (this.state.urlParams.has('p3nnywis3')) {
            if (this.state.urlParams.has('token') && this.state.urlParams.get('token') != null && this.state.urlParams.get('token') !== "") {
                checkConfig(this.state.urlParams, props)
            } else {
                window.location.href = '/oauth/index'
            }
        }
        let unloadWindowUTCTimeString = localStorage.getItem("PennyWindowUnloadTime")
        if(unloadWindowUTCTimeString != null)
        {
            localStorage.removeItem("PennyWindowUnloadTime")    
            const unloadWindowUTCTime = new Date(unloadWindowUTCTimeString);
            if(unloadWindowUTCTime!= null)
            {
                // Get the current time as a Date object
                const currentTime = new Date();
                
                // Calculate the difference in milliseconds
                const timeDifference = currentTime.getTime() - unloadWindowUTCTime.getTime();
                
                // Check if the difference is within 15 minutes (900,000 milliseconds)
                if (timeDifference >= 900000) {
                    this.props.dispatch({ type: LOG_OUT, logedIn: false })
                    this.props.clearListCookies({ nav: "/oauth/log_out", loading: false, logout: true })
                }
            }

    
        }
        if (this.state.urlParams.has('ids_token')) {
            let mobieWrapped = new URLSearchParams(window.location.search)

            let mobileWr = mobieWrapped?.has('mobile_wrap')

            if (mobileWr) {
                localStorage.setItem("PennyTime", mobieWrapped?.get('mobile_wrap'))
            }

            props.dispatch({
                type: SET_TOKEN,
                token: this.state.urlParams.get('ids_token'),
                mobileWraped: mobileWr,
                ...(mobileWr ? { selectedLoanNumber: mobieWrapped?.get('mobile_wrap') } : null)
            })
            this.props.history.push(window.location.pathname)
        }
        window.addEventListener('beforeinstallprompt', e => {
            e.preventDefault()
            props.dispatch({ type: OFFLINE_MODE, event: e })
        })
        window.addEventListener('beforeunload', e => {
            if(window.location.pathname != '/SuccessfulLogout')
            {
                props.trackerDispatcher(UserLanguage_ENUM.English,
                    TrackerCategoryLID_ENUM.Button,
                    TrackerHitTypeLID_ENUM.Event,
                    TrackerActionLID_ENUM.Click,
                    "Window unloaded by user action or system", 0);
                // Create a new Date object
                let currentDateUTC = new Date();
                // Get current UTC time as a string
                let currentUTCTime = currentDateUTC.toUTCString();
                                
                localStorage.setItem("PennyWindowUnloadTime", currentUTCTime);

            }

        })
        localStorage.removeItem("navigation")
        window.addEventListener('storage', storageEventHandler, false);
        function storageEventHandler(evt) {
            let valu = localStorage.getItem("navigation")

            if (valu) {
                that.props.history.push(valu)
                that.props.authenticate()
            }

        }

        this.channel.addEventListener('message', (event) => {
            let route = event.data
            that.props.history.push(route)
            that.props.authenticate()
        })

    }

    static getDerivedStateFromProps(props, state) {
        let isProductAndFeaturesEnabled = getActiveFeatures(props.layout?.features, `View_Products_And_Offers_web`, props.layout?.whitelisted)

        var isDirected = false;
        if (env == "staging" || env == "development") {
            window.layout = props.layout
        }
        if (props?.layout?.selectedLoanNumber) {
            Sentry.configureScope(scope => {
                scope.setTag("transaction_id", `Pennymac Loan NUmber: ${props.layout.selectedLoanNumber}`);
            });
        }

        if (props.layout?.pennyWise) {
        } else if (props.layout && props.layout?.currentLoan) {
            MarketingScripts(getActiveFeatures(props.layout?.features, `ad_butler_code`, false), props.layout);
        }

        if (!state.languageSet && props.layout?.customerPreference && props.layout?.customerPreference.length > 0) {
            let lang = props.layout?.customerPreference?.find(s => s.prefenceTypeId == 300097)?.prefenceValueTypeId == 300100 ? "es" : "en"
            props.dispatch({ type: CHANGE_LANGUAGE, lang: lang, dontPost: true })
            state.languageSet = true
        }
        return {
            ...state,
            directed: isDirected,
            isProductAndFeaturesEnabled: isProductAndFeaturesEnabled,
        }

    }

    updateTracking = () => {
        let { layout } = this.props
        if (parseInt(layout.selectedLoanNumber) > 0) {

            let there = localStorage.getItem("PennyTime")
            let currentLoanId = parseInt(there) || layout.selectedLoanNumber

            if (currentLoanId > 0 || there == "there") {
                fetch(`/api/account/track_ui_session`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${layout.token}`,
                    },
                    body: JSON.stringify({ loanId: layout.selectedLoanNumber })
                }).catch(err => { })
            } else {
                this.props.trackerDispatcher(UserLanguage_ENUM.English,
                    TrackerCategoryLID_ENUM.Button,
                    TrackerHitTypeLID_ENUM.Event,
                    TrackerActionLID_ENUM.Click,
                    "LogOut Clicked Via Web SessionTimeOut", 0)
                this.props.clearListCookies({ nav: "/oauth/log_out", loading: false, logout: true })
            }
        }
    }

    handleOnIdle = (event) => {
        this.props.trackerDispatcher(UserLanguage_ENUM.English,
            TrackerCategoryLID_ENUM.Button,
            TrackerHitTypeLID_ENUM.Event,
            TrackerActionLID_ENUM.Click,
            "LogOut Clicked Via Web SessionTimeOut", 0)
        this.props.dispatch({ type: LOG_IN, logedIn: false })
        this.props.clearListCookies({ nav: "/oauth/log_out", loading: true, logout: true })
    }

    setupTest = (data) => {
        if (env == "staging" || env == "development") {
            window.layout = data
        }
    }
    render() {

        let { layout, translations, dispatch } = this.props


        const blackListRoutes = ['/EmailVerification', '/SuccessfulLogout', '/post_debit_payment', '/Home/Terms', '/Movr/redirect']
        return <PnmacContext>
            {!layout.pennyWise && window.location.pathname?.indexOf("/SuccessfulLogout") == -1 && env != "development" ?
                <PnmacSessionTimer
                    closeCallBack={() => null}
                    throttle={1000}
                    onAction={(throttle) => this.updateTracking()}
                    handleOnIdle={this.handleOnIdle}
                    timeOutTime={870000} // 14 minutes 30 seconds in milli seconds
                    message={translations?.IdleMessageLogout}
                    keepMeLoggedIn={translations?.IdleMessageTitle}
                    title={translations?.IdleMessageTitle}
                    timeoutint={"45"}
                    userIdleDetected={(data)=>{
                        this.props.dispatch({
                            type: USER_IDLE_TIMEOUT,
                            value: data
                        })
                    }}
                />
                : null}
            <LocalizationProvider dateAdapter={MomentUtils}>
                {/* <Suspense fallback={<div style={{ marginTop: '25%', display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: "32px", fontWeight: "bold" }}>
                    <div>
                        <div>
                            <FadeLoader
                                size={200}
                                color={"#003187"}
                                loading={true}
                            />
                        </div>
                    </div>
                </div>}> */}
                {(blackListRoutes.find(e => window.location.pathname.indexOf(e) > -1) == undefined) ? <PrivateRoute registration={window.location.pathname == "/Account/Registration"} penny={this.state.urlParams.has('p3nnywis3')}>
                    {window.location.pathname !== "/Account/Registration" ?
                        <Layout>
                            {/*<button onClick={() => {*/}
                            {/*    window.open("https://localhost:44380/Loan")*/}
                            {/*}}>Click me</button>*/}
                            <Switch>
                                <Route exact path='/' component={Landing} />
                                <Route exact path='/UpdateApp' component={ForceUpdate} />
                                <Route exact path='/Payment' render={props => <Payment {...props} testFixture={(test) => { this.setupTest(test) }} isOtp={true} />} />
                                <Route exact path='/ClosedEndSeconds' render={props => <Payment {...props} testFixture={(test) => { this.setupTest(test) }} isOtp={true} />} />
                                <Route exact path='/Payment/Ach' render={props => <Payment {...props} testFixture={(test) => { this.setupTest(test) }} isOtp={false} />} />
                                <Route exact path='/Payment/AchUpdate' render={props => <Payment {...props} testFixture={(test) => { this.setupTest(test) }} isOtp={false} refreshProp={true} />} />
                                <Route exact path='/Payment/Pending' component={PendingPayments} />
                                <Route exact path='/Payment/AchSuccess' component={AchSuccess} />
                                <Route exact path='/StatementsAndDocuments' component={StatementsAndDocuments} />
                                <Route exact path='/AmortizationCalculator' component={AmortizationCalculator} />
                                <Route exact path='/Home/FAQ' component={FAQ} />
                                <Route exact path='/Loan' component={LoanSummary} />
                                <Route exact path='/MessageCenter' component={MessageCenter} />
                                <Route exact path='/Home/Contact' component={ContactUs} />
                                <Route exact path='/Loan/Activity' component={LoanActivity} />
                                <Route exact path='/Loan/Escrow' component={LoanEscrow} />
                                <Route exact path='/Loan/MortgageInsurance' component={MortgageInsurance} />
                                <Route exact path='/Loan/EscrowAnalysisPaymentChange' component={EscrowAnalysisPaymentChange} />
                                <Route exact path='/Loan/WisconsinTaxOptions' component={WisconsinTaxOptions} />
                                <Route exact path='/Loan/TaxOptions' component={TaxOptions} />
                                {this.state.isProductAndFeaturesEnabled && <Route exact path='/Offers' component={Offers} />}
                                <Route path='/Payoff/:id?/:doc?/:payoffMethod?' component={PayoffDemand} />
                                <Route path='/Account/:account' component={AccountSettingsPage} />
                                <Route path='/Mod/Forms' component={ModForms} />
                                <Route path='/Mod' component={Mod} />
                                <Route path='/Leaving' component={Interstitial} />
                                <Route path='/Payment/ReturnToPennyMac' render={(props) => {
                                    this.channel.postMessage("/Payment/Pending", layout.pennyHomeFullyQualified);
                                    localStorage.setItem("navigation", "/Payment/Pending")
                                    setTimeout(function () {
                                        window.close()
                                    }, 500)
                                    return <div></div>
                                }} />
                                <Route path='/Payment/ReturnToPnmac' render={(props) => {
                                    this.channel.postMessage("/Payment/Pending", layout.pennyHomeFullyQualified);
                                    localStorage.setItem("navigation", "/Payment/Pending")
                                    setTimeout(function () {
                                        window.close()
                                    }, 500)
                                    return <div></div>
                                }} />
                                <Route exact path='/MakeADraw' render={props => <MakeADraw {...props} makeadraw={true} />} />
                                <Route exact path='/MakeADraw/ViewPendingDraws' render={props => <MakeADraw {...props} makeadraw={false} />} />
                                <Route exact path='/movr' component={MovrRed} />
                                <Route component={Default} />
                            </Switch>
                        </Layout> : <Route exact path='/Account/Registration' component={Registration} />
                    }
                </PrivateRoute> :
                    <GenericLayout translations={translations} showHeader={window.location.pathname?.indexOf("/SuccessfulLogout") == -1}>
                        <Switch>
                            <Route path="/post_debit_payment" component={BillMatrixHolder} />
                            <Route path='/EmailVerification/:verify' component={EmailVerification} />
                            <Route exact path='/SuccessfulLogout' component={SuccessfulLogout} />
                            <Route path='/account/login' component={LogIn} />
                            <Route path='/Home/Index' component={SuccessfulLogout} />
                            <Route path='/Movr/redirect' component={MovrRedirect} />
                        </Switch>
                    </GenericLayout>}
                {/* </Suspense> */}
            </LocalizationProvider>
        </PnmacContext>
    }
}
const mapStateToProps = (state) => ({
    layout: state,
    translations: state.translations

});

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
        ...bindActionCreators(AppActions, dispatch),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
