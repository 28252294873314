import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { AppActions } from 'pnmac-universal-components'


class MovrRedirect extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    static getDerivedStateFromProps(props, state) {

        let { selectedLoanNumber,movr_url } = props.layout
        if (selectedLoanNumber) {
            var form = document.createElement("form");
            form.action = movr_url;
            form.method = "POST";
            form.target = "_self";

            var input = document.createElement("input");
            input.name = "LoanNumber";
            input.value = selectedLoanNumber;
            form.appendChild(input);

            form.style.display = 'none';
            document.body.appendChild(form);
            form.submit();
        }
        return state
    }

    render() {
        return <div>Redirecting</div>
    }
}


const mapStateToProps = state => ({
    layout: state,
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
        ...bindActionCreators(AppActions,
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MovrRedirect)
