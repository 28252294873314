import React, { Component } from 'react';
import { useEffect } from 'react';

const env = window.location.hostname ?
    window.location.hostname.indexOf("-dev") > -1 ? "development" :
        window.location.hostname.indexOf("-qa") > -1 ? "qa" :
            window.location.hostname.indexOf("-stg") > -1 ? "staging" :
                window.location.hostname.indexOf("localhost") == -1 ? "production" : "debug" : "debug"



export default function MovrRedirect() {
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const cookie = urlParams.get('movr_session_cookie')
        const selectedLoanNumber = urlParams.get('selectedLoanNumber')
        const movr_url = urlParams.get('movr_url')

        const cookieName = env === 'production' ? '' : '_identity_staging_pennymac_com_session'
        document.cookie = cookieName + '=' + cookie
        if (selectedLoanNumber && cookie && movr_url) {
            const form = document.createElement("form");
            form.action = movr_url;
            form.method = "POST";
            form.target = "_self";

            const input = document.createElement("input");
            input.name = "LoanNumber";
            input.value = selectedLoanNumber;
            form.appendChild(input);

            form.style.display = 'none';
            document.body.appendChild(form);
            form.submit();
        }
    }, [])


    return <div></div>
}
