export const MarketingScripts = (toggle, layout) => {
    var env = window.location.host.indexOf("-") > -1 || window.location.host.indexOf("localhost") > -1
    window.dataLayer = window.dataLayer || [];
    if (toggle) {
        if (!isNaN(layout.segmentId)) {
            var digitalData = {
                user: {
                    userName: layout.userProfile?.username,
                    ipAddress: "174.128.191.1",
                    loanNumber: layout.currentLoan.loanId,
                    loanStatus: layout.currentLoan.loanStatusDescription,
                    cookiesEnabled: "Yes",
                    state: layout.currentLoan?.propertyRegion,
                    city: layout.currentLoan?.propertyLocality,
                    zip: layout.currentLoan?.propertyPostalCode,
                    segmentId: layout.segmentId
                },
                page: {
                    pageName: "cr" + window.window.getCurrentPageName(),
                    channel: "customers",
                    pageUrl: "",
                    environment: "production",
                    lead: {
                        form1: {
                            name: {},
                            fields: {
                                currentCustomer: {},
                                loanType: {},
                                state: {}
                            },
                            errors: {}
                        },
                        phone: {},
                        calculator: {
                            name: {}
                        },
                        cta: {
                            linkName: {}
                        },
                        chat: {},
                        customerWebsite: {
                            linkName: {}
                        },
                        rates: {
                            request: {
                                loanPurpose: {},
                                propertyValue: {},
                                loanAmount: {},
                                state: {},
                                county: {},
                                isVa: {}
                            },
                            response: {
                                currentProductName: {}
                            },
                            mobile: {
                                direction: {},
                                section: {}
                            }
                        }
                    }
                }
            };
            window.window.digitalData = digitalData
        }
        if (!document.getElementById('marketing-script')) {
            let data = { event: 'marketingScriptData' },
                fields = ['borrowerId', 'borrowerLoans', 'language', 'loanArray', 'loanNumbers', 'logedIn', 'logedInState', 'mobile', 'segmentData', 'segmentId', 'selectedLoanNumber'],
                userFields = ['customer_id', 'is_active', 'login_count', 'previous_login_on', 'user_id', 'username', 'verified_email'];
            fields.forEach(field => {
                if (layout[field])
                    data[field] = layout[field];
            });
            if (layout.userProfile) {
                data.userProfile = {};
                userFields.forEach(field => {
                    if (layout.userProfile[field])
                        data.userProfile[field] = layout.userProfile[field];
                });
            }
            window.dataLayer.push(data);

            if (env) {
                let lower = document.createElement("script");
                lower.setAttribute("src", "https://assets.adobedtm.com/c52783cec8b1/b490159dbd62/launch-7d7413fb35dc-staging.min.js");
                lower.setAttribute("async", "true");
                lower.setAttribute("id", "marketing-script");
                document.body.appendChild(lower);
            } else {
                let prd = document.createElement("script");
                prd.setAttribute("src", "https://assets.adobedtm.com/c52783cec8b1/b490159dbd62/launch-830b0903b192.min.js");
                prd.setAttribute("async", "true");
                prd.setAttribute("id", "marketing-script");
                document.body.appendChild(prd);
            }
        }
    }
}
