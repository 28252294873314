import React, {Component, useState} from "react";
import {View, Text, Image, Platform, TouchableOpacity} from "react-native";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import NavBar from "./NavBar";
import Footer from "./Footer";
import {withRouter} from "react-router";
import {
    PnmacUIButton,
    PnmacAppbarHeader,
    PnmacAppbarContent,
    PnmacToaster,
    PnmacActivityIndicatorGif,
    PnmacMediaQuery,
    AppActions,
    AppEvents,
    Events,
    MacChat,
    Hoverable,
    isSubServicingLoan
} from 'pnmac-universal-components'
import MenuIcon from '@material-ui/icons/Menu';
import {getActiveFeatures, toCamel, windowObject} from '../utils.js'
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import {isIE} from 'react-device-detect';
import {Dialog, DialogContent} from '@material-ui/core';
import _ from "underscore";

const {TrackerHitTypeLID_ENUM, TrackerActionLID_ENUM, TrackerCategoryLID_ENUM, UserLanguage_ENUM} = AppEvents
const {
    TOGGLE_DRAWER, LOG_IN, FEATURE_TOGGLES, GET_MESSAGES, PAYMENT_VALIDATION, E_DIS,
    CHANGE_LANGUAGE
} = AppActions

const StyledDialog = withStyles({
    root: {
        zIndex: 90,
        "& .MuiPaper-root": {backgroundColor: "transparent", overflow: "visible"},
        "& .MuiPaper-elevation24": {boxShadow: "none"}
    },
})(Dialog);

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        lineHeight: 1.5,
        fontFamily: [
            'HalenoirCompactText-Regular',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(',')
    },
})(Button);


function PnmacMenu(props) {
    let {translations, matches} = props
    const [hovering, setHovering] = useState(false)
    const handleClick = (event) => {
            props.history.push(`/Account/AccountSettings`)
            if (props.layout.drawerOpen) {
                props.dispatch({type: TOGGLE_DRAWER, toggle: false});
            }
       
    };


    return (
        <div>
            {matches.small ? <Hoverable
                    onHoverIn={() => setHovering(true)}
                    onHoverOut={() => setHovering(false)}
                >
                    <TouchableOpacity
                        onPress={handleClick}
                        style={[
                            hovering ? styles.hoverTabBg : styles.defaultTabBg,
                            window.location.pathname === '/Account/AccountSettings' && styles.selectTabBg
                        ]}
                    >
                        <Image style={{
                            height: 20,
                            width: 20,
                            marginBottom: 5,
                            tintColor: window.location.pathname === '/Account/AccountSettings' ? '#117AAA' : '#1B1B1B'
                        }}
                               source={require("../assets/gear.svg")}/>
                        <Text
                            style={[styles.defaultTab, window.location.pathname === '/Account/AccountSettings' && styles.selectTab]}>{translations.Settings}</Text>
                    </TouchableOpacity>
                </Hoverable> :
                <BootstrapButton style={{
                    ...(window.location.pathname === '/Account/AccountSettings' ? styles.selectTab : styles.defaultTab),
                    fontSize: matches.small ? 12 : 16,
                    padding: matches.small ? '0px 9px' : '6px 12px',
                    marginRight: 5
                }} onClick={handleClick}>
                    {translations.AccountSettings}
                </BootstrapButton>}
        </div>
    );
}

function MiniDrawer(props) {

    let {translations, matches, layout} = props
    const [hoveringLang, setHoveringLang] = useState(false)
    const [hoveringContact, setHoveringContact] = useState(false)
    const [hoveringLogout, setHoveringLogout] = useState(false)

    function getSubServicerLogo(msrCode){
        return `${translations.ImageBaseURL}${msrCode}/logo.png`;
    }

    return (
        <header className="border-bottom-1">
            <div>
                <PnmacAppbarHeader
                    style={{height: 85, backgroundColor: 'white'}}
                >

                    <PnmacAppbarContent

                        title={
                            matches.small || matches.medium ? <div style={{width: "15%"}}>

                                    <div
                                        onClick={() =>
                                            props.dispatch({type: TOGGLE_DRAWER, toggle: true})
                                        }

                                    >
                                        <MenuIcon titleAccess="Menu icon" style={{color: "#1B1B1B"}}/>
                                    </div>

                                </div> :
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    flexGrow: 1
                                }}>
                                   {
                                        isSubServicingLoan(layout)?
                                        <img alt="Logo" style={{width:300}} src={getSubServicerLogo(layout.servicerId)}/>  
                                        :
                                        <img alt="Pennymac logo" style={{height: 30}} src={require("../assets/IDSPennyHomeLogo.svg")}/>  
                                    }
                                </div>

                        }
                    />


                    <div style={{
                        paddingRight: 15,
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: matches.small ? "85%" : "initial",
                        marginTop: matches.small ? 10 : 0,
                        marginBottom: matches.small ? 10 : 0,
                    }}>
                        {matches.small ?
                            <Hoverable
                                onHoverIn={() => setHoveringLang(true)}
                                onHoverOut={() => setHoveringLang(false)}
                            >
                                <TouchableOpacity
                                    style={hoveringLang ? styles.hoverTabBg : styles.defaultTabBg}
                                    onPress={() => {

                                        props.dispatch({
                                            type: CHANGE_LANGUAGE,
                                            lang: layout.language == "en" ? 'es' : 'en', dontPost: true
                                        })

                                    }}>{layout.language == "en" ?
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Image style={{
                                            height: 20,
                                            width: 20,
                                            marginBottom: Platform.OS === 'android' ? 3 : 4
                                        }} source={require("../assets/globe.svg")}/>
                                        <Text style={styles.defaultTab}>Español</Text>
                                    </View>
                                    :
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Image style={{
                                            height: 20,
                                            width: 20,
                                            marginBottom: Platform.OS === 'android' ? 3 : 4
                                        }} source={require("../assets/globe.svg")}/>
                                        <Text style={styles.defaultTab}>English</Text>
                                    </View>}
                                </TouchableOpacity>
                            </Hoverable> :
                            <TouchableOpacity
                                onPress={() => {

                                    props.dispatch({
                                        type: CHANGE_LANGUAGE,
                                        lang: layout.language == "en" ? 'es' : 'en', dontPost: true
                                    })

                                }}
                            >{layout.language == "en" ?
                                <BootstrapButton style={{
                                    ...styles.defaultTab,
                                    fontSize: matches.small ? 12 : 16,
                                    padding: matches.small ? '0px 9px' : '6px 12px',
                                }}>Español</BootstrapButton> :
                                <BootstrapButton {...props} style={{
                                    ...styles.defaultTab,
                                    fontSize: matches.small ? 12 : 16,
                                    padding: matches.small ? '0px 9px' : '6px 12px',
                                }}>English</BootstrapButton>}
                            </TouchableOpacity>
                        }

                        {matches.small ?<Hoverable
                            onHoverIn={() => setHoveringContact(true)}
                            onHoverOut={() => setHoveringContact(false)}
                        >
                            <TouchableOpacity
                                style={[
                                    hoveringContact ? styles.hoverTabBg : styles.defaultTabBg,
                                    window.location.pathname === '/Home/Contact' && styles.selectTabBg
                                ]}
                                onPress={() => {

                                        props.history.push(`/Home/Contact`);
                                        props.trackerDispatcher(UserLanguage_ENUM.English, TrackerCategoryLID_ENUM.Button, TrackerHitTypeLID_ENUM.Event, TrackerActionLID_ENUM.Click, "Contact Us Link Clicked", 0);
                                    
                                }}
                            >
                                <Image
                                    style={{
                                        height: 20,
                                        width: 20,
                                        marginBottom: 5,
                                        tintColor: window.location.pathname === '/Home/Contact' ? '#117AAA' : '#1B1B1B'
                                    }}
                                    source={require("../assets/phone.svg")}

                                />
                                <Text
                                    style={[styles.defaultTab, window.location.pathname === '/Home/Contact' && styles.selectTab]}>{translations.contactUs}</Text>
                            </TouchableOpacity>
                        </Hoverable> :
                            <div>
                                <BootstrapButton onClick={() => {

                                        props.history.push(`/Home/Contact`);
                                        props.trackerDispatcher(UserLanguage_ENUM.English, TrackerCategoryLID_ENUM.Button, TrackerHitTypeLID_ENUM.Event, TrackerActionLID_ENUM.Click, "Contact Us Link Clicked", 0);
                                   
                                }} style={{
                                    ...(window.location.pathname === '/Home/Contact' ? styles.selectTab : styles.defaultTab),
                                    fontSize: matches.small ? 12 : 16,
                                    padding: matches.small ? '0px 9px' : '6px 12px',
                                }}>{translations.contactUs}</BootstrapButton>
                            </div>}

                        {!layout.pennyWise && <div>
                            <PnmacMenu {...props} />
                        </div>}
                        {!layout.pennyWise ? matches.small ? <Hoverable
                            onHoverIn={() => setHoveringLogout(true)}
                            onHoverOut={() => setHoveringLogout(false)}
                        >
                            <TouchableOpacity style={hoveringLogout ? styles.hoverTabBg : styles.defaultTabBg}
                                                                               onPress={() => {
                                                                                   props.trackerDispatcher(
                                                                                       UserLanguage_ENUM.English,
                                                                                       TrackerCategoryLID_ENUM.Button,
                                                                                       TrackerHitTypeLID_ENUM.Event,
                                                                                       TrackerActionLID_ENUM.Click,
                                                                                       " LogOut Button Clicked",
                                                                                       0
                                                                                   )
                                                                                   props.dispatch({
                                                                                       type: LOG_IN,
                                                                                       logedIn: false
                                                                                   })
                                                                                   props.clearListCookies({
                                                                                       nav: "/oauth/log_out",
                                                                                       loading: true,
                                                                                       logout: true
                                                                                   })
                                                                               }}
                            >
                                <Image style={{height: 20, width: 20, marginBottom: 5}}
                                       source={require("../assets/right-from-bracket.svg")}/>
                                <Text style={styles.defaultTab}>{translations.logout}</Text>
                            </TouchableOpacity>
                            </Hoverable>:
                            <PnmacUIButton
                                mode={'contained'}
                                onClick={() => {
                                    props.trackerDispatcher(
                                        UserLanguage_ENUM.English,
                                        TrackerCategoryLID_ENUM.Button,
                                        TrackerHitTypeLID_ENUM.Event,
                                        TrackerActionLID_ENUM.Click,
                                        " LogOut Button Clicked",
                                        0
                                    )

                                    props.dispatch({type: LOG_IN, logedIn: false})
                                    props.clearListCookies({nav: "/oauth/log_out", loading: true, logout: true})
                                }}
                            >
                                {translations.logout}
                            </PnmacUIButton> : null}
                    </div>
                </PnmacAppbarHeader>
            </div>
        </header>
    );
}

let hubCallback = null

class Layout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customerObjectModel: null,
            loanActivity: null,
            loanSummary: null,
            docTemplates: null,
            Redirect: false,
            loanNumber: null,
            showMessage: null,
            pennyCanTalk: false,
            pennyCanHear: false,
            showChat: false,
            isSpeaking: false,
            isListening: false,
            timer: null,
            closeIconHover: false,
            incomingMessages: [<div key="initial"></div>],
            hubIntitailized: false,
        }
        hubCallback = this.takePayload;
    }

    static getDerivedStateFromProps(props, state) {

        if (!props.layout?.pennyWise && props.layout?.token && parseInt(props.layout?.borrowerId) > 0 && !state.hubIntitailized && hubCallback != null) {

            Events.init(hubCallback, props.layout.token)

            return {
                ...state,
                hubIntitailized: true
            }
        }
        return null
    }

    takePayload = (message, type) => {
        let incomingMessages = this.state.incomingMessages

        switch (type) {
            case "FeatureToggle":
                if (message) {
                    let mess = JSON.parse(message)
                    this.props.dispatch({type: FEATURE_TOGGLES, features: mess})
                }
                break;
            case "ReceiveMessage":

                incomingMessages.push(<div key={incomingMessages.length}>{message.toString()}</div>)
                this.setState({
                    showMessage: true,
                    incomingMessages: incomingMessages
                }, () => {
                    setTimeout(() => {
                        incomingMessages.splice(0, 1)
                        this.setState({showMessage: false})
                    }, 1000)
                })
                break;
            case "InternalMessage":
                message = JSON.parse(message)
                switch (message.type) {
                    case '1':
                    case 1:
                        let {layout} = this.props
                        var messag = layout.messages
                        if (messag.find(s => s.messageId == message.dataModel.MessageId)) {
                            messag.forEach(s => {
                                if (s.messageId == message.dataModel.MessageId) {
                                    s.body = message.dataModel.Body;
                                }
                            })
                        } else {

                            messag.push({
                                "idsUserId": message.dataModel.IdsUserId,
                                "messageId": message.dataModel.MessageId,
                                "parentMessageId": message.dataModel.ParentMessageId,
                                "body": message.dataModel.Body,
                                "receivedDateTimeUtc": message.dataModel.ReceivedDateTimeUtc,
                                "readDateTimeUtc": message.dataModel.ReadDateTimeUtc,
                                "subjectLID": message.dataModel.SubjectLID,
                                "statusLID": message.dataModel.StatusLID,
                                "messageTypeLID": message.dataModel.MessageTypeLID,
                                "documentId": message.dataModel.DocumentId,
                                "recipientLID": message.dataModel.RecipientLID,
                                "senderLID": message.dataModel.SenderLID,
                                "messageSourceLID": message.dataModel.MessageSourceLID,
                                "firstName": message.dataModel.FirstName,
                                "lastName": message.dataModel.LastName,
                                "chatLog": message.dataModel.ChatLog,
                                "loanId": message.dataModel.LoanId,
                                "attachment": message.dataModel.Attachment,
                            })
                        }

                        this.props.dispatch({type: GET_MESSAGES, messages: messag})
                        break;
                    case 2:
                    case "2":

                        let {selectedLoanNumber, closedEndSeconds} = this.props.layout
                      
                        if(parseFloat(selectedLoanNumber) > 0 && !closedEndSeconds){
                            this.props.refreshBankData(selectedLoanNumber)
                        }
                        break;
                    case 3:
                    case "3":

                        let {banksToAdd} = this.props.layout

                        let newBank = message.dataModel

                        if (newBank) {
                            if (banksToAdd && banksToAdd.length > 0) {
                                banksToAdd.forEach(s => {
                                    if (s.loanBankDetailAltId == newBank.loanBankDetailAltId) {
                                        return newBank
                                    }
                                    return s
                                })
                            } else {
                                banksToAdd = []
                                banksToAdd.push(newBank)
                            }
                            this.props.dispatch({type: E_DIS, banksToAdd: banksToAdd})

                        } else {

                            this.props.dispatch({type: E_DIS, banksToAdd: (banksToAdd || []).filter(s => !s.pending)})

                        }

                        break;
                }

                break;
        }

    }

    socketPrepend = null


    render() {
        let {layout, translations, dispatch, ...rest} = this.props
        let {
            chatMessages,
            pennyCanTalk,
            pennyCanHear,
            isSpeaking,
            isListening,
            chatIsInitialized,
            chatIsBlocked
        } = layout
        let trans = (translations && translations[windowObject.location.pathname]) ? JSON.parse(translations[windowObject.location.pathname]) : []

        return (
            <div>


                <PnmacMediaQuery queries={{
                    xSmall: "(max-width: 281px)",
                    small: "(max-width: 599px)",
                    medium: "(min-width: 600px) and (max-width: 1199px)",
                    large: "(min-width: 1200px)"
                }}>
                    {matches => (
                        <div>
                            <StyledDialog
                                open={layout.loading}
                                BackdropProps={{style: {backgroundColor: "rgba(0, 0, 0, 0.45)"}}}
                            >
                                <DialogContent style={{
                                    position: "relative",
                                    backgroundColor: "white",
                                    borderRadius: 5,
                                    color: "#707070",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 190,
                                    height: isIE ? 160 : 150
                                }}>
                                    <div style={{height: isIE ? "100%" : "auto", width: isIE ? "90%" : "auto"}}>
                                        <PnmacActivityIndicatorGif
                                            style={{height: isIE ? 40 : "auto", alignSelf: isIE ? "center" : "auto"}}
                                            size={80} image={require("../assets/spinner.gif")}/>
                                    </div>
                                    <p style={{fontSize: 16, marginTop: 15}}>Loading...</p>
                                </DialogContent>

                            </StyledDialog>
                            {!layout.wrapedMobile && <MiniDrawer matches={matches} {...this.props} />}
                            {layout.pennyWise ? <div style={{
                                height: 50,
                                backgroundColor: "red",
                                display: "flex",
                                flexDirection: "row",
                                alignContent: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                            }}>
                                <div>Penny Wise</div>
                            </div> : null}
                            <PnmacToaster
                                visible={this.state.showMessage}
                                position={50}
                                shadow={true}
                                animation={true}
                                hideOnPress={true}
                                customStyles={{
                                    position: 'fixed',
                                    left: 0,
                                    right: 0,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 100000
                                }}
                            >{this.state.incomingMessages}</PnmacToaster>
                            <MacChat
                              {...{ layout, translations, matches, dispatch, ...rest }}
                              {...{ chatMessages, pennyCanTalk, pennyCanHear, isSpeaking, isListening, chatIsInitialized, chatIsBlocked }}
                              trackerDispatcher={this.props.trackerDispatcher}
                            />


                            {matches.small || matches.medium ? <NavBar matches={matches} {...this.props} /> : null}
                            <div>
                                <div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: matches.small || matches.medium ? "column" : "row",
                                        backgroundColor: "#F5F5F5",
                                        padding: matches.small ? 10 : 20,
                                        justifyContent: "space-between"
                                    }}>
                                        <div role="navigation" style={{flexBasis: '25%', flexGrow: 1, flexShrink: 1}}>
                                            {matches.large ?
                                                <div>
                                                    <div style={{width: '90%', margin: "auto", minWidth: "350px"}}>
                                                        <NavBar matches={matches} {...this.props} />
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                        {!this.props.layout.drawerOpen ?
                                            <div role="main" style={{flexBasis: '75%', flexGrow: 2, flexShrink: 2}}>
                                                <div>
                                                    {trans && (
                                                        <div>
                                                            {trans.map((w, i) =>
                                                                <div
                                                                    key={i}
                                                                    style={{
                                                                        margin: 10,
                                                                        borderRadius: 10,
                                                                        padding: 10,
                                                                        backgroundColor: '#EDFAFF',
                                                                        borderWidth:1,
                                                                        border:'1px solid',
                                                                        borderColor:'#CBEAF5',
                                                                }}
                                                                >
                                                                    <div className={'htmlToNative'}
                                                                         style={{ fontSize: 14, fontFamily:'HalenoirCompactText-Regular'}}
                                                                         dangerouslySetInnerHTML={{__html: w.body}}/>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                {this.props.children}
                                            </div> : null}
                                    </div>
                                </div>
                            </div>

                            <Footer {...this.props} matches={matches} />
                        </div>
                    )}
                </PnmacMediaQuery>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    layout: state,
    translations: state.translations

});

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
        ...bindActionCreators(AppActions, dispatch),
    };
};


const styles = {
    selectTab: {
        fontFamily: "HalenoirCompactText-Bold",
        fontSize: 12,
        color: '#117AAA',
        textAlign: 'center',
    },
    defaultTab: {
        fontFamily: "HalenoirCompactText-Medium",
        fontWeight: '500',
        fontSize: 12,
        color: '#1B1B1B',
        textAlign: 'center',
        width: '100%'
    },
    selectTabBg: {
        minWidth: 60,
        maxWidth: 80,
        backgroundColor: '#EAF5F8',
        marginHorizontal: 5,
        borderRadius: 5,
        alignItems: 'center',
        paddingVertical:10,

    },
    defaultTabBg: {
        paddingVertical:10,
        minWidth: 60,
        maxWidth: 80,
        height: 60,
        marginHorizontal: 5,
        borderRadius: 10,
        alignItems: 'center',
    },
    hoverTabBg: {
        paddingVertical:10,
        minWidth: 60,
        maxWidth: 80,
        marginHorizontal: 5,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#EAF5F8',
        borderWidth:1,
        borderColor:'#39AFDD'
    },
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout))

