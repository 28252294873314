import React from "react";
import PropTypes from "prop-types";
import { StyleSheet } from "react-native";
import { PnmacText } from 'pnmac-universal-components';
import stepStarted from "../../assets/step-started.svg";
import stepFuture from "../../assets/step-future.svg";
import stepCompleted from "../../assets/step-completed.svg";
import step5CompletedMobile from "../../assets/step5-completed-mobile.svg";

const StepsIndicator = ({
  currentStep, steps, matches, completed
}) => {

    const colorCyan = "#30AEDF";
    const colorGreen = "#56AC69"; 
    const colorGrayMediumDark = "#5C5C5C";

    const LargeMedium = () => (
        <>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: '90%',
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10
            }}>
                {steps.map((step, index) => {           
                    
                    let stepColor = currentStep === index ? colorGrayMediumDark : colorGreen;

                    if(completed) stepColor = colorGreen;

                    return <PnmacText style={{color: stepColor, fontSize: 25, fontFamily: "HalenoirCompactText-Bold" }}>{step.stepNumber}</PnmacText>
                })}
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: '90%',
            }}>
            
                {steps.map((step, index) => {

                    let stepIcon = stepFuture;
                    if(currentStep === index) stepIcon = stepStarted;            
                    if(currentStep > index) stepIcon = stepCompleted;

                    let stepColor = index === steps.length - 2? colorCyan : colorGreen;
                    if(completed){ stepColor = colorGreen; stepIcon = stepCompleted };

                    return (
                        <>                                                           
                            <img src={stepIcon} alt="Step Started" style={{width: 30, height: 30}} />                    
                            
                            {index < steps.length -1 && (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    height: 3,
                                    backgroundColor: stepColor,
                                    marginLeft: 5,
                                    marginRight: 5,
                                }}/>
                            )}                    
                        </>
                    );
                })}
            </div>
            
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: '100%',    
                marginTop: 10,
            }}>
                {steps.map((step, index) => {
                                
                    let stepColor = currentStep === index ? colorGrayMediumDark : colorGreen;
                    if(completed) stepColor = colorGreen;
        
                    return (
                        <div style={{width: 150, display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <PnmacText style={{textAlign: "center", color: stepColor, fontSize: 16, fontFamily: "HalenoirCompactText-Bold"}}>{step.stepLabel}</PnmacText>
                        </div>
                    );
                })}
            </div>           
        </>
    );

    const currentStepObject = steps[currentStep];

    const Small = () => (
        <> 
            <div
                style={{
                    width:"100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: 20,
                    paddingBottom: 20
                }}
            >
                {completed ? 
                    <img src={step5CompletedMobile} alt="Step 5 Completed" style={{width: 40, height: 40}} /> :<div 
                    style={{
                        width: 40,
                        height: 40,
                        borderRadius: 40/2,
                        backgroundColor: "white",
                        justifyContent: "center",
                        borderStyle: "solid",
                        alignItems: "center",
                        display: "flex",
                        borderWidth: 2,
                        borderColor: colorCyan
                    }}
                >
                     
                    <div 
                        style={{
                            width: 30,
                            height: 30,
                            borderRadius: 15,
                            backgroundColor: colorCyan,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex"
                        }}
                    >
                        <PnmacText 
                            style={{
                                    color: "white",
                                    fontSize: 16,
                                    fontWeight: "bold"
                                }}>
                            {currentStepObject.stepNumber}
                        </PnmacText>
                    </div>
                </div>}

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 10
                }}>
                    <PnmacText style={{ color: colorGrayMediumDark, fontWeight: "bold" }}>{`STEP ${currentStepObject.stepNumber}/${steps.length}`}</PnmacText>
                    <PnmacText
                        style={{
                            color: completed ? colorGreen : colorCyan,
                            fontSize: 16,
                            fontWeight: completed ? "bold" : "normal",
                            fontFamily: "HalenoirCompactText-Bold",
                        }}
                    >
                        {currentStepObject.stepLabel}
                    </PnmacText>
                </div>
            </div>

            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: '100%',
            }}>            
                {steps.map((step, index) => {

                    let stepIcon = stepFuture;
                    if(currentStep === index) stepIcon = stepStarted;            
                    if(currentStep > index) stepIcon = stepCompleted;

                    let stepColor = colorGreen;

                    if(currentStep === index) stepColor = colorCyan;
                    if(currentStep > index) stepIcon = colorGreen;
                    if(completed) stepColor = colorGreen;

                    return (                                                                                                                                                         
                        <div style={{
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: 3,
                            backgroundColor: stepColor,
                            marginLeft: 2,
                            marginRight: 2,
                        }}/>
                    );
                })}
            </div>                               
        </>
    );

    if(matches.large || matches.medium) return <LargeMedium/>;
    return <Small/>;
};

const StepShape = PropTypes.shape({
    stepNumber: PropTypes.number.isRequired,
    stepLabel: PropTypes.string.isRequired
});

StepsIndicator.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(StepShape).isRequired,
  matches: PropTypes.object.isRequired,
  completed: PropTypes.bool.isRequired
};

export default StepsIndicator;

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    line: {        
        height: 2,
        width: "100%", 
        backgroundColor: "black"
    },
    circle: {
        width: 30,
        height: 30,
        borderRadius: 25,
        backgroundColor: "green",
        alignItems: "center",
        justifyContent: "center"
    },
    blueCircle: {
        backgroundColor: "blue"
    },
    greenCircle: {
        backgroundColor: "green"
    },
    stepNumber: {
        fontSize: 16,
        fontWeight: "bold",
        color: "black",
    },
    stepLabel: {
        fontSize: 14,
        color: "black",
        
    }
})
