import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router";
import FadeLoader from "react-spinners/FadeLoader";
import { AppActions } from 'pnmac-universal-components'
class PrivateRoutePnMac extends React.Component {

    constructor(props) {
        super(props)
        if (!props.penny && !props.registration) {
            this.props.authenticate() 
        }

    }


    render() {
        const { location, translations } = this.props;
        if ((this.props.layout.logedIn || this.props.penny) && translations && this.props?.layout?.uiData) {
            return this.props.children
        }

        if (location.pathname.indexOf("Registration") > -1) {
            return this.props.children
        }
        return (
            <div style={{ marginTop: '25%', display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: "32px", fontWeight: "bold" }}>
                <div>
                    <div>
                    
                        <FadeLoader
                            size={200}
                            color={"#003187"}
                            loading={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    layout: state,
    translations: state.translations

})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
        ...bindActionCreators(AppActions,
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrivateRoutePnMac))