import React from 'react'
import { Redirect } from 'react-router'
export default class Default extends React.PureComponent {
    render() {
        return <Redirect to="/" />
    }
}

export class DefaultReg extends React.PureComponent {
    render() {
        return <Redirect to="/Account/Registration" />
    }
}